<template>
  <v-card height="190px">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          {{ address.name }}
        </v-col>
        <v-col v-if="isChef" cols="12" sm="6" class="py-0">
          {{ address.type | capitalize}}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          {{ address.address1 }}
        </v-col>
        <v-col cols="12" class="py-0">
          {{ address.address2 }}
        </v-col>
        <v-col cols="12" class="py-0">
          {{ address.city }},

          {{ address.state }}

          {{ address.zipcode }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green" @click.stop="editAddress" class="mr-3 white--text">
        edit
      </v-btn>
      <v-btn color="red" @click.stop="removeAddress" v-if="!hide_remove" class="white--text">
        remove
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['address', 'index', 'hide_remove', 'isChef'],

  methods: {
    editAddress() {
      this.$emit('editAddress', this.index)
    },
    removeAddress() {
      this.$emit('removeAddress', this.index)
    }
  }

}
</script>
